import 'dotenv/config'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import React from 'react'
import ChooseCalendar from './components/ChooseCalendar'
import Homepage from './components/Homepage'
import PrivacyPolicy from './components/PrivacyPolicy'
import {publicUrl} from './configs'

export default () => (
  <Router>
    <Route exact path={`${publicUrl}/`} component={Homepage} />
    <Route path={`${publicUrl}/choose-calendar/:service`} component={ChooseCalendar} />
    <Route path={`${publicUrl}/privacy-policy`} component={PrivacyPolicy} />
  </Router>
)
