import React from 'react'
import './style.css'
import Navigation from '../Navigation'
import Section from '../Section'
import logo from './logo.png'

const Homepage = () => (
  <div className={'container'}>
    <div className={'navigation'}>
      <Navigation
        urls={[
          { name: 'homepage', url: '', active: true },
          { name: 'privacy policy', url: 'privacy-policy' },
          { name: 'login', url: '' }
        ]}
      />
    </div>
    <div className={'content'}>
      <img src={logo} alt={'Logo Equip.'} className={'image'} />
      <div className={'section'}>
        <Section
          title={'welcome'}
          content={`Welcome to Equip, the app that will let you improve your team colaboration and reliability. In equip we have two roles: leaders and colaborators. Leaders are responsible for making sure their collaborators are solving the right requests, while collaborator are responsible for commiting responsibly to request from others, and to ask for what they need but cannot do by themselves.`}
        />
      </div>
      <div className={'section'}>
        <Section
          title={' priorities and events'}
          content={`All Collaborators have in their main page a section called Agenda, which holds all the commitments and meetings they have scheduled for: today, tommorrow or the future. To see a commit in your agenda, another collaborator has to request you something and after you commit to it the commitment will show up. To see your meetings in your agenda, first you have to synchronize with google Calendar, and every time you create a meeting in your Calendar it will appear in Equip. `}
        />
      </div>
      <div className={'section'}>
        <Section
          title={'How can I request a demo?'}
          content={`If your organization is looking for a SaaS that can improve their collaboration and productivity, email as anibal.cerda at equip.cloud. See you soon!`}
        />
      </div>
    </div>
  </div>
)

export default Homepage
