import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Button from '../Button'
import Google from '../../icons/googlecalendar'
import Outlook from '../../icons/googlecalendar'
import styles from './SelectCalendar.module.css'

const SelectCalendar = ({ calendars, onSave, service }) => {
  const [showOptions, setShowOptions] = useState(false)
  const [selectedCalendar, setSelectedCalendar] = useState(null)
  const onSelectOption = calendarId => {
    setSelectedCalendar(calendarId)
    setShowOptions(false)
  }

  const title = selectedCalendar
    ? calendars.find(e => e.id === selectedCalendar).summary
    : 'Seleccione'

  return (
    <div className={styles.container}>
      <div className={styles.flexColumn}>
        {service === 'google' ? (
          <Google size={25} />
        ) : (
          <Outlook size={25} />
        )}
        <div className={styles.title}>Seleccione un calendario</div>
        {showOptions ? (
          <div className={styles.containerOptions}>
            {calendars.map(e => (
              <div
                className={styles.sections}
                key={e.id}
                onClick={() => onSelectOption(e.id)}
              >
                <div className={styles.sectionsI}>{e.summary}</div>
              </div>
            ))}
          </div>
        ) : (
          <div
            className={styles.selectTitle}
            onClick={() => setShowOptions(true)}
          >
            {title}
          </div>
        )}

        <Button
          className={cx(styles.btn, styles.submitButton, {
            [styles.pointer]: selectedCalendar
          })}
          label='Guardar'
          disabled={!selectedCalendar}
          onClick={() => onSave(selectedCalendar)}
        />
      </div>
    </div>
  )
}

SelectCalendar.defaultProps = {
  calendars: []
}

SelectCalendar.propTypes = {
  calendars: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSave: PropTypes.func.isRequired,
  service: PropTypes.oneOf(['google', 'outlook'])
}

export default SelectCalendar
