import React from 'react'
import PropTypes from 'prop-types'
import style from './Button.module.css'
import cx from 'classnames'

const Button = ({
  onClick,
  disabled,
  defaultButton,
  type,
  className,
  label
}) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={cx(
      {
        [style.disabled]: disabled,
        [style.btn]: defaultButton,
        [style.imageBtn]: !defaultButton
      },
      style[type],
      style.btn,
      className
    )}
  >
    {label}
  </button>
)

Button.defaultProps = {
  defaultButton: true
}

Button.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.string
}

export default Button
