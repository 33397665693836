'use strict'

Object.defineProperty(exports, '__esModule', {
  value: true
})

const _extends =
  Object.assign ||
  function (target) {
    for (let i = 1; i < arguments.length; i++) {
      const source = arguments[i]
      for (const key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key]
        }
      }
    }
    return target
  }

const _react = require('react')

const _react2 = _interopRequireDefault(_react)

const _reactIconBase = require('react-icon-base')

const _reactIconBase2 = _interopRequireDefault(_reactIconBase)

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj }
}

const Close = function Close(props) {
  return _react2.default.createElement(
    _reactIconBase2.default,
    _extends({ viewBox: '0 0 24 24' }, props),
    _react2.default.createElement(
      'g',
      null,
      _react2.default.createElement('path', {
        d: 'M8.5 7.1L7.1 8.5 10.6 12 7.1 15.5 8.5 16.9 12 13.4 15.5 16.9 16.9 15.5 13.4 12 16.9 8.5 15.5 7.1 12 10.6 8.5 7.1z'
      })
    )
  )
}

exports.default = Close
module.exports = exports.default
