import React from 'react'
import PropTypes from 'prop-types'
import Header from './Header'
import './style.css'

const Section = ({ title, content }) => (
  <div>
    <Header title={title} />
    <p>{content}</p>
  </div>
)

Section.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string
}

export default Section
