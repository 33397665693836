'use strict'

Object.defineProperty(exports, '__esModule', {
  value: true
})

const _extends =
  Object.assign ||
  function (target) {
    for (let i = 1; i < arguments.length; i++) {
      const source = arguments[i]
      for (const key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key]
        }
      }
    }
    return target
  }

const _react = require('react')

const _react2 = _interopRequireDefault(_react)

const _reactIconBase = require('react-icon-base')

const _reactIconBase2 = _interopRequireDefault(_reactIconBase)

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj }
}

const Info = function Info(props) {
  return _react2.default.createElement(
    _reactIconBase2.default,
    _extends({ viewBox: '0 0 24 24' }, props),
    _react2.default.createElement(
      'g',
      null,
      _react2.default.createElement('path', {
        d: 'M2 12C2 6.5 6.5 2 12 2 17.5 2 22 6.5 22 12 22 17.5 17.5 22 12 22 6.5 22 2 17.5 2 12Zm1.9 0c0 4.5 3.6 8.1 8.1 8.1 4.5 0 8.1-3.6 8.1-8.1C20.1 7.5 16.5 3.9 12 3.9 7.5 3.9 3.9 7.5 3.9 12ZM12 6.4c-0.7 0-1.3 0.4-1.3 1v5.5c0 0.6 0.5 1 1.3 1 0.7 0 1.3-0.4 1.3-1V7.4C13.3 6.8 12.7 6.4 12 6.4Zm-1.2 10c0-0.7 0.6-1.2 1.2-1.2 0.7 0 1.2 0.6 1.2 1.2 0 0.7-0.6 1.2-1.2 1.2-0.7 0-1.2-0.6-1.2-1.2z'
      })
    )
  )
}

exports.default = Info
module.exports = exports.default
