import React from 'react'
import PropTypes from 'prop-types'
import './style.css'
import cx from 'classnames'
import {Link} from 'react-router-dom'
import { authUrl } from '../../../configs'

const NavigationSection = ({ element, index, disabled }) => {
  return (
    <div
    key={index}
      className={cx('url', { 'disabled': disabled })}
      style={{
        backgroundColor: element.active ? '#FF9300' : '#fff',
        border: '1px solid #043163',
        marginLeft: '-1px'
      }}
      // onClick={() => browserHistory.push(`/${element.url}`)}
    >
      <div
        className={'rotation'}
        style={{
          color: element.active ? '#fff' : '#043163'
        }}
      >
        {element.name !== 'login' ? 
        <Link to={`/${element.url}`}>{element.name}</Link> :
        <a href={authUrl}>{element.name}</a> 
}
      </div>
    </div>
  )
}

/**
 * @property {object} element - Section information
 * @property {number} key - Section id
 * @property {bool} disabled - If disabled component
 */

NavigationSection.propTypes = {
  element: PropTypes.object,
  index: PropTypes.number,
  disabled: PropTypes.bool
}

export default NavigationSection
