import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FadingCircle } from 'better-react-spinkit'
import Info from '../../icons/info'
import SelectCalendar from '../SelectCalendar'
import LockScreen from '../LockScreen'
import ErrorPanel from '../ErrorPanel'
import { post } from 'axios'
import {authUrl, isHttps, publicUrl} from '../../configs'

const ChooseCalendar = ({
  location,
  match: {
    params: { service }
  }
}) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [hostname, setHostname] = useState(null)
  const [calendars, setCalendars] = useState([])

  const params = new URLSearchParams(location.search)
  const code = params.get('code')
  const userId = params.get('state')

  useEffect(() => {
    const fetchData = async () => {
      setError(null)
      setLoading(true)
      try {
        const {
          data: { calendars, hostname, error }
        } = await post(`${authUrl}${publicUrl}/${service}-login`, {
          code,
          userId
        })

        if (!error) {
          setCalendars(calendars)
          setHostname(hostname)
        } else {
          setHostname(hostname)
          setError(error)
        }
      } catch (e) {
        setError(e)
      }
      setLoading(false)
    }
    fetchData()
  }, [userId, code, service])

  const onSave = async calendarId => {
    try {
      setLoading(true)
      setCalendars(null)
      const {
        data: { ok, error: setCalendarError }
      } = await post(
        `${authUrl}${publicUrl}/set-${service}-calendar`,
        {
          calendarId,
          userId
        }
      )

      if (ok) {
        window.open(`${isHttps ? 'https': 'http'}://${hostname}`, '_self')
      } else {
        setError(setCalendarError)
      }
    } catch (e) {
      console.log(e)
      setError(e)
    }
    setLoading(false)
  }

  const errorMessage = `Algo salió mal. Por favor vuelva a sincronizar su cuenta de ${
    service === 'google' ? 'Google' : 'Outlook'
  }.`

  return (
    <LockScreen>
      {loading ? (
        <FadingCircle size={130} color={'#FD953A'} />
      ) : (
        <div>
          {calendars && calendars.length > 0 && !error && (
            <SelectCalendar
              onSave={onSave}
              calendars={calendars}
              service={service}
            />
          )}
          {error && (
            <ErrorPanel
              icon={<Info size={60} />}
              text={errorMessage}
              onClosePanel={() => {
                setError(null)
                window.open(`${isHttps ? 'https': 'http'}://${hostname}`, '_self')
              }}
            />
          )}
        </div>
      )}
    </LockScreen>
  )
}

ChooseCalendar.propTypes = {
  location: PropTypes.object,
  hostname: PropTypes.string
}

export default ChooseCalendar
