import React from 'react'
import './style.css'
import NavigationSection from './NavigationSection'
import PropTypes from 'prop-types'

const Navigation = ({ urls }) => { 

  return (
    <div className={'tabs-container'}>
      {urls.map((element, key) => {
        return (<NavigationSection
          element={element}
          index={key}
          key={key}
        />)
      })}
    </div>
  )
}

Navigation.propTypes = {
  urls: PropTypes.array,
  seenTutorialsVideos: PropTypes.array,
  changeUi: PropTypes.func,
  isLeader: PropTypes.bool
}

export default Navigation
