import React from 'react'
import PropType from 'prop-types'
import cx from 'classnames'
import styles from './LockScreen.module.css'

const LockScreen = ({ children, notCenter }) => (
  <div className={cx(styles.container, { [styles.itemPosition]: !notCenter })}>
    {children}
  </div>
)

LockScreen.propType = {
  children: PropType.element.isRequired,
  notCenter: PropType.bool
}

export default LockScreen
