import React from 'react'
import './style.css'
import Navigation from '../Navigation'
import Section from '../Section'

const PrivacyPolicy = () => (
  <div className={'container'}>
    <div className={'navigation'}>
      <Navigation
        urls={[
          { name: 'homepage', url: '' },
          { name: 'privacy policy', url: 'privacy-policy', active: true },
          { name: 'login', url: '/' }
        ]}
      />
    </div>
    <div className={'content'}>
      <div className={'section'}>
        <Section
          title={'Privacy Policy'}
          content={`Equip ("us", "we", or "our") operates the equip.cloud website (the "Service").

          This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.
          
          We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy.`}
        />
      </div>
      <div className={'section'}>
        <Section
          title={'Information Collection And Use'}
          content={`We collect several different types of information for various purposes to provide and improve our Service to you.

          Types of Data Collected:
          1) Personal Data
          While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:
          
          Email address
          First name and last name
          Usage Data
          We may also collect information how the Service is accessed and used ("Usage Data"). This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
          
          2) Tracking & Cookies Data: 
          We use cookies and other scripts "tracking technologies" to track your activity on our Service and hold certain information.
          
          Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service.
          
          You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.`}
        />
      </div>
      <div className={'section'}>
        <Section
          title={'Use of Data'}
          content={`Equip uses the collected data for various purposes:

          To provide and maintain the Service
          To notify you about changes to our Service
          To allow you to participate in interactive features of our Service when you choose to do so
          To provide customer care and support
          To provide analysis or valuable information so that we can improve the Service
          To monitor the usage of the Service
          To detect, prevent and address technical issues`}
        />
      </div>
      <div className={'section'}>
        <Section
          title={'Use of Data from Google Calendar'}
          content={`If you decide to synchronize your Equip Account with one of your Google Calendars, Equip will use Google's API (auth/calendar.readonly scope) to access on your behalf all the events from the calendar of your choosing, store them in our service, and keep updating them as changes are made (we "subscribe" to changes with google's API). We will use that information to display them on your "Agenda" (main Equip page) so you have in one place all information relevant to be productive in your day. We only read your events, we do not add, delete nor change them in any way. If you would like to detach your google calendar account you only have to click on the same button you synchronized it, and we will delete all access to your google's account info. We do not share your information with any third party service and the information is only kept our computers.`}
        />
      </div>
      <div className={'section'}>
        <Section
          title={'Transfer Of Data'}
          content={`Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.
          
          Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
          
          Equip will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.`}
        />
      </div>
      <div className={'section'}>
        <Section
          title={'Security Of Data'}
          content={`The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.`}
        />
      </div>
      <div className={'section'}>
        <Section
          title={'Service Providers'}
          content={`We may employ third party companies and individuals to facilitate our Service ("Service Providers"), to provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used.

          These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.`}
        />
      </div>
      <div className={'section'}>
        <Section
          title={'Links To Other Sites'}
          content={`Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.

          We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.`}
        />
      </div>
      <div className={'section'}>
        <Section
          title={"Children's Privacy"}
          content={`Our Service does not address anyone under the age of 18 ("Children").

          We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Children has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.`}
        />
      </div>
      <div className={'section'}>
        <Section
          title={'Changes To This Privacy Policy'}
          content={`We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.

          We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.
          
          You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.`}
        />
      </div>
      <div className={'section'}>
        <Section
          title={'Contact Us'}
          content={`If you have any questions about this Privacy Policy, please contact us by mail at anibal.cerda (at) 3s.cl`}
        />
      </div>
    </div>
  </div>
)

export default PrivacyPolicy
