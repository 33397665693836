'use strict'

Object.defineProperty(exports, '__esModule', {
  value: true
})

const _extends =
  Object.assign ||
  function (target) {
    for (let i = 1; i < arguments.length; i++) {
      const source = arguments[i]
      for (const key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key]
        }
      }
    }
    return target
  }

const _react = require('react')

const _react2 = _interopRequireDefault(_react)

const _reactIconBase = require('react-icon-base')

const _reactIconBase2 = _interopRequireDefault(_reactIconBase)

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj }
}

const GoogleCalendar = function GoogleCalendar(props) {
  return _react2.default.createElement(
    _reactIconBase2.default,
    _extends({ viewBox: '0 0 48 48' }, props),
    _react2.default.createElement(
      'g',
      null,
      _react2.default.createElement(
        'g',
        { clipRule: 'evenodd' },
        _react2.default.createElement('path', {
          d: 'M38 5H10a2 2 0 0 0-2 2v3h32V7a2 2 0 0 0-2-2zM14 8a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm20 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2z',
          fill: '#c7c7c7'
        }),
        _react2.default.createElement('path', {
          d: 'M44 11c.103-.582-1.409-2-2-2H6c-1 0-2.103 1.418-2 2 .823 4.664 3 15 3 15h34s2.177-10.336 3-15z',
          fill: '#1976d2'
        }),
        _react2.default.createElement('path', {
          d: 'M41 26H7S4.823 36.336 4 41c0 1.146.792 2 2 2h36c.591 0 2-.5 2-2-.823-4.664-3-15-3-15z',
          fill: '#1e88e5'
        }),
        _react2.default.createElement('path', {
          d: 'M20.534 26c.984.325 1.687.85 2.105 1.557.433.732.65 1.55.65 2.457 0 1.582-.519 2.826-1.556 3.733-1.037.906-2.363 1.36-3.977 1.36-1.582 0-2.892-.427-3.93-1.282-1.038-.855-1.536-2.014-1.497-3.476l.036-.072h2.242c0 .914.28 1.642.841 2.182.56.541 1.33.811 2.308.811.994 0 1.773-.27 2.337-.811.564-.541.847-1.34.847-2.397 0-1.073-.25-1.864-.751-2.373-.501-.509-1.292-.763-2.373-.763h-2.051V26h4.769zM31.637 26h2.349v8.856h-2.349z',
          fill: '#fafafa'
        }),
        _react2.default.createElement('path', {
          d: 'M14.727 22.036h-2.254l-.024-.072c-.04-1.312.435-2.427 1.425-3.345.99-.918 2.284-1.377 3.882-1.377 1.606 0 2.886.427 3.84 1.282.954.855 1.431 2.073 1.431 3.655 0 .716-.217 1.429-.65 2.141-.433.712-1.083 1.254-1.95 1.628l.107.052h-4.77v-.911h2.051c1.042 0 1.779-.26 2.212-.781.433-.521.65-1.246.65-2.176 0-.994-.246-1.749-.739-2.266-.493-.517-1.22-.775-2.182-.775-.914 0-1.648.268-2.2.805-.534.518-.81 1.202-.829 2.14zM33.986 26h-2.349v-6.218l-3.554.048v-1.694l5.903-.644z',
          fill: '#e0e0e0'
        }),
        _react2.default.createElement('path', {
          d: 'M6 9c-1.438 0-2.103 1.418-2 2 .823 4.664 3 15 3 15M41 26s2.177-10.336 3-15c0-1.625-1.409-2-2-2',
          fill: '#1976d2'
        })
      )
    )
  )
}

exports.default = GoogleCalendar
module.exports = exports.default
