import React from 'react'
import PropTypes from 'prop-types'
import './style.css'

const Header = ({ title }) => (
  <div data-disabled-sorter='on' className={'sectionTitleC'}>
    <div className={'sectionTitle'}>{title}</div>
  </div>
)

Header.propTypes = {
  title: PropTypes.string
}

export default Header
