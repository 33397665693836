import React from 'react'
import Close from '../../icons/close'
import PropTypes from 'prop-types'
import style from './ErrorPanel.module.css'

const ErrorPanel = ({ icon, text, header, onClosePanel }) => (
  <div className={style.errorPanel}>
    <div className={style.icon}>{icon}</div>
    <div className={style.errorLabel}>
      {header && <div>{header}</div>}
      <div>{text}</div>
    </div>
    <Close className={style.errorClear} onClick={onClosePanel} size={24} />
  </div>
)

ErrorPanel.propTypes = {
  text: PropTypes.string,
  onClosePanel: PropTypes.func,
  notCO: PropTypes.string,
  icon: PropTypes.node,
  header: PropTypes.string
}

export default ErrorPanel
